import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby";

import styles from "./footer.module.css"

const Footer = ({ className }) => (
  <div className={styles.footer}>
      <footer>
          <nav className={styles.nav}>
              <Link className={styles.navItem} to="/impressum">Impressum</Link>
              <Link className={styles.navItem} to="/datenschutz">Datenschutz</Link>
          </nav>
      </footer>
  </div>
)

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className: ''
}

export default Footer;
