import PropTypes from "prop-types"
import React from "react"

import styles from "./icon.module.css"


const Icon = ({ Svg, className }) => (
  <Svg className={styles.icon} />
)

Icon.propTypes = {
  Svg: PropTypes.func,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Icon.defaultProps = {
  Svg: () => {},
  className: null
}

export default Icon
