import React from 'react';


import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider  from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';
import Stage    from '../stage/stage';
import IconNav  from '../iconNav/iconNav';

import Facebook from '../../../images/social/facebook.svg';
import linkedin from '../../../images/social/linkedin.svg';
import twitter  from '../../../images/social/twitter.svg';
import xing     from '../../../images/social/xing.svg';

import styles from './stageContact.module.css'

const StageContact = () => (
  <Stage anchor="kontakt" styleFull="secondary" className={styles.stage}>
    <Headline tag="h3" visualStyle="h1">Schreib eine E-Mail:</Headline>
    <Slugline tag="h2">info@chancencheckin.de</Slugline>
    <Devider />
    <Text align="center">Folge uns auf<br />Social Media:</Text>
    <IconNav
      menuItems={[
        {
          title: 'Visit us at linkedin',
          href: 'https://www.linkedin.com/company/chancencheckin',
          svg: linkedin,
          target: '_blank'
        },{
          title: 'Visit us at xing',
          href: 'https://www.xing.com/companies/chancencheck-in',
          svg: xing,
          target: '_blank'
        },{
          title: 'Visit us at facebook',
          href: 'https://www.facebook.com/ChancenCheckin-114273210206814',
          svg: Facebook,
          target: '_blank'
        },{
          title: 'Visit us at twitter',
          href: 'https://twitter.com/Chancencheckin',
          svg: twitter,
          target: '_blank'
        }
      ]}
    />
  </Stage>
)
export default StageContact;
