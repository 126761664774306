import PropTypes from "prop-types"
import React from "react"
import BackgroundImage from 'gatsby-background-image'

import styles from "./stage.module.css"

const Stage = ({children, anchor, bgImage, alignment, alignVert, fullWidth, fullHeight, styleFull, styleContainer, className }) => {

  const fullWidthStyle = fullWidth ? styles.fullWidth : null;
  const fullHeightStyle = fullHeight ? styles.fullHeight : null;
  const fullStyle = styleFull ? styles[styleFull + 'Style'] : null;
  const containerStyle = styleContainer ? styles[styleContainer + 'Style'] : null;
  const alignmentStyle = alignment ? styles[alignment] : null;
  const alignVertStyle = alignVert ? styles[alignVert] : null;

  if (bgImage) {
    return (
      <BackgroundImage
        Tag="section"
        className={[styles.imageStage, className].join(' ')}
        fluid={bgImage}
        backgroundColor={`#F3F3F3`}
        id={anchor}
      >
        <div className={[styles.container, fullWidthStyle, fullHeightStyle, containerStyle, alignVertStyle, alignmentStyle].join(' ')}>
          {children}
        </div>
      </BackgroundImage>
    );
  }

  return (
    <section id={anchor} className={[styles.stage, fullStyle, className].join(' ')}>
      <div className={[styles.container, fullWidthStyle, fullHeightStyle, containerStyle, alignVertStyle, alignmentStyle].join(' ')}>
        {children}
      </div>
    </section>
  );
}

Stage.propTypes = {
  /** Stage ID for anchor navigation */
  anchor: PropTypes.string,
  /** Stretched backround image */
  bgImage: PropTypes.object,
  /** vertical content alignment oriented on flexbox jusitify-content options */
  alignVert: PropTypes.oneOf(['top', 'middle', 'bottom', 'spaceBetween', 'spaceAround', 'spaceEvenly']),
  /** vertical content alignment oriented on flexbox align-items options */
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  /** Style of the wrapping container wich has always 100% width */
  styleFull: PropTypes.oneOf(['primary', 'secondary', 'light']),
  /** Style of the content container wich has a limited content width */
  styleContainer: PropTypes.oneOf(['primary', 'secondary', 'light']),
  /** The content container becomes a 100% width */
  fullWidth: PropTypes.bool,
  /** The content container becomes full height of the browser viewport */
  fullHeight: PropTypes.bool,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Stage.defaultProps = {
  anchor: '',
  bgImage: null,
  alignment: 'center',
  styleFull: null,
  styleContainer: null,
  fullWidth: false,
  fullHeight: false,
  className: null
}

export default Stage;
