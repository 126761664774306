import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import Navigation from "./navigation/navigation";

import styles from "./header.module.css";

const Header = ({ children, logoText, menuItems, className }) => {

  return (
    <div className={[styles.wrapper, className].join(' ')}>
        <header className={styles.header}>
            <div className={styles.logo}><Link to="/">{logoText}</Link></div>
            <div className={styles.content}>{children}</div>
            <Navigation menuItems={menuItems} />
        </header>
    </div>
  )
}

Header.propTypes = {
  logoText: PropTypes.string,
  menuItems: PropTypes.array,
  className: PropTypes.string
}

Header.defaultProps = {
  logoText: '',
  menuItems: [],
  className: ''
}

export default Header;
