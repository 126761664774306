import PropTypes from "prop-types"
import React from "react"
import CookieConsent from "react-cookie-consent";

import Sticky from "./molecules/sticky/sticky";
import Header from "./organisms/header/header";
import Footer from "./organisms/footer/footer";

import "../styles/variables.css"
import "../styles/global.css"

const menuItems = [
  // {
  //   href: '/unternehmer',
  //   target: '_self',
  //   title: 'Für Unternehmer'
  // },
  {
    href: '/unterstuetzer',
    target: '_self',
    title: 'Für Unterstützer'
  },
  {
    href: '/ueber-uns',
    target: '_self',
    title: 'Über Uns'
  }
];

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, siteTitle }) => (
  <>
    <Sticky>
      <Header logoText={siteTitle} menuItems={menuItems} />
    </Sticky>
    <main style={{flex: 1, marginTop: 'calc(var(--headerHeight, 80px) * -1)'}}>
      {children}
    </main>
    <Footer />
    <CookieConsent
      location="bottom"
      buttonText="Ich Akzeptiere"
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => window.location.reload()}
      style={{ background: 'var(--colorSecondary)' }}
      buttonStyle={{ backgroundColor: 'var(--colorPrimary)', color: 'var(--colorLight)', fontSize: "var(--fontSize)" }}
      expires={150}
      enableDeclineButton={true}
      declineButtonText='Lieber nicht'
      declineButtonStyle={{ backgroundColor: 'var(--colorGrey3)', fontSize: 'var(--fontSize)' }}
    >
      Zur stetigen verbesserung unserer Webeite setzen wir Google Analytics als Analysewerkzeug ein.
      Alle Daten werden anonymisiert. Weitere Informationen zur Datenverarbeitung findet Ihr in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
    </CookieConsent>
  </>
)

Layout.propTypes = {
  siteTitle: PropTypes.string
}

Layout.defaultProps = {
  siteTitle: ''
}

export default Layout;
