import PropTypes from "prop-types"
import React from "react"

import Icon from '../../atoms/icon/icon';

import styles from "./iconNav.module.css"

const IconNav = ({ menuItems, className }) => (
  <nav className={[styles.iconNav, className].join(' ')}>
    {menuItems.map((item, index) => (
      <a
        key={index}
        href={item.href}
        target={item.target}
        rel={item.target === '_blank' ? 'noreferrer' : ''}
        aria-label={item.title}
      >
        <Icon Svg={item.svg} />
      </a>
    ))}
  </nav>
)

IconNav.propTypes = {
  /** Array of navigation items */
  menuItems: PropTypes.arrayOf(PropTypes.object),
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

IconNav.defaultProps = {
  menuItems: [],
  className: null
}

export default IconNav
