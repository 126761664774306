import PropTypes from "prop-types"
import React from "react"

import styles from "./popup.module.css"

const Popup = ({ children, active, className }) => {

  const activeStyle = active ? styles.active : '';

  return (
    <div className={[styles.popup, activeStyle, className].join(' ')}>
        {children}
    </div>
  )
}
Popup.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string
}

Popup.defaultProps = {
  active: false,
  className: ''
}

export default Popup;
