import PropTypes from "prop-types";
import React, {useState} from "react";
import { Link } from 'gatsby';

import Burger from '../burger/burger';
import Popup  from '../popup/popup';

import styles from './navigation.module.css';

const Navigation = ({menuItems, className }) => {

  const [burgerActive, setBurgerActive] = useState(false);

  const toggleBurgerClickHandler = () => {
    setBurgerActive(!burgerActive);
  }

  let mobileMenu;

  if (burgerActive) {
    mobileMenu = (
      <Popup active={burgerActive}>
        <Link onClick={toggleBurgerClickHandler} to="/" target="_self">Startseite</Link>
        {menuItems.map(item => (
          <Link key={item.title} onClick={toggleBurgerClickHandler} to={item.href} target={item.target ? '_self' : item.target}>{item.title}</Link>
        ))}
      </Popup>
    );
  }

  return (
    <>
      <div className={[styles.nav, className].join(' ')}>
          <nav className={styles.items}>
          {menuItems.map(item => (
            <Link key={item.title} to={item.href} target={item.target ? '_self' : item.target}>{item.title}</Link>
          ))}
          </nav>
      </div>
      <Burger active={burgerActive} clickHandler={toggleBurgerClickHandler} />
      {mobileMenu}
    </>
  )
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      target: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  className: PropTypes.string
}

Navigation.defaultProps = {
  menuItems: [],
  className: ''
}

export default Navigation;
