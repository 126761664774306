import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";

import styles from "./sticky.module.css"

const Sticky = ({children, className }) => {

  const [isSticky, setSticky] = useState(false);
  const ref = useRef();
  const handleScroll = () => {
    setSticky(ref.current.offsetTop > 0);
  }

  useEffect( () => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    }
  })

  return (
    <div
      ref={ref}
      className={[
        styles.sticky,
        isSticky ? styles.isSticky : '',
        className
      ].join(' ')}>
      {children}
    </div>
  )
}

Sticky.propTypes = {
  className: PropTypes.string
}

Sticky.defaultProps = {
  className: ''
}

export default Sticky;
