import PropTypes from "prop-types"
import React from "react"

import styles from "./text.module.css"

const Text = ({children, tag, align, fontSize, className }) => {

  const Tag = tag;

  return (
    <Tag className={[styles.text, styles[align], styles[fontSize], className].join(' ')}>
      {children}
    </Tag>
  )
}

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  tag: PropTypes.string,
  fontSize: PropTypes.oneOf(['big', 'normal', 'small']),
  className: PropTypes.string
}

Text.defaultProps = {
  align: 'left',
  tag: 'p',
  fontSize: 'normal',
  className: null
}

export default Text;
