import PropTypes from "prop-types"
import React from "react"

import styles from "./devider.module.css"

const Devider = ({ className }) => (
  <hr className={[styles.devider, className].join(' ')} />
)

Devider.propTypes = {
  className: PropTypes.string
}

Devider.defaultProps = {
  className: ''
}

export default Devider
